import { useRouter } from "next/router";
import { useEffect } from "react";

const postResizeMessage = () => {
  const height = document.body.scrollHeight;
  const width = document.body.scrollWidth;
  window?.parent?.postMessage({ type: "resizeIframe", height, width }, "*");
  window?.parent?.postMessage({ type: "resizeIframe", height, width }, "*");
};

export const usePostIframeSize = () => {
  const router = useRouter();
  useEffect(() => {
    postResizeMessage();
    window.addEventListener("resize", postResizeMessage);

    return () => {
      window.removeEventListener("resize", postResizeMessage);
    };
  }, [router.asPath]);
};
