import { Theme, UnbrandedEducationTheme } from "@modernatx/ui-kit-react";

import { ProCovidTheme } from "../disease/pro/covid/_common/theme/us";

export const JPCovidTheme: Theme = {
  ...UnbrandedEducationTheme,
  fonts: {
    ...UnbrandedEducationTheme.fonts,
    body: "NotoSansJP",
    headlineLevel1: "NotoSansJP"
  },
  colorsLight: {
    ...ProCovidTheme.colorsLight,
    iconButtonHoverBackgroundColor: "#56B4E9",
    iconButtonBackgroundColor: "#D1D2D5",
    iconButtonActiveBackgroundColor: "#1E2846"
  },
  colorsDark: {
    ...ProCovidTheme.colorsDark,
    link01: "#FFFFFF"
  }
};
