import { Theme } from "@modernatx/ui-kit-react";

import { HccTheme } from "./hcc";

const buttonStyles: Partial<Theme["colorsLight"]> = {
  buttonBackgroundColorPrimary: "#01283C",
  buttonBorderColorPrimary: "#01283C",
  buttonActiveBackgroundColorPrimary: "#01283C",
  buttonActiveBorderColorPrimary: "#01283C",
  buttonHoverBackgroundColorPrimary: "#01283C",
  buttonHoverBorderColorPrimary: "#01283C",
  buttonBackgroundColorSecondary: "#FFF",
  buttonBorderColorSecondary: "#D1D2D5",
  buttonColorSecondary: "#01283C"
};

const borderStyles: Partial<Theme["borders"]> = {
  tableHeaderBorder: "0.5px solid #D8D8D8",
  tableBodyBorder: "0.5px solid #E1E1E1"
};

export const EuaTheme: Theme = {
  ...HccTheme,
  colorsLight: {
    ...HccTheme.colorsLight,
    ...buttonStyles,
    background02: "#005E83",
    background03: "#FFF",
    backgroundNeutral03: "#1e2845",
    tableHeaderBackgroundColor: "#01283C",
    tableBodyEvenBackgroundColor: "#F9F9FA",
    tableBodyOddBackgroundColor: "#FFF",
    text04: "#191F2A"
  },
  colorsDark: {
    ...HccTheme.colorsDark,
    accent01: "#ADDCF2",
    background02: "#005E83",
    background03: "#FFF",
    backgroundNeutral03: "#1e2845",
    tableHeaderBackgroundColor: "#01283C",
    tableBodyEvenBackgroundColor: "#F9F9FA",
    tableBodyOddBackgroundColor: "#FFF"
  },
  borders: {
    ...HccTheme.borders,
    ...borderStyles
  }
};
