import React from "react";

export const Cost = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g clipPath="url(#clip0_4202_13589)">
        <mask id="mask0_4202_13589" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
          <path d="M80 0H0V80H80V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4202_13589)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 4.05335C32.8904 4.05335 25.9405 6.16159 20.0291 10.1115C14.1177 14.0613 9.51033 19.6754 6.78961 26.2438C4.06889 32.8122 3.35703 40.0399 4.74404 47.0129C6.13105 53.9858 9.55464 60.3909 14.5819 65.4182C19.6091 70.4454 26.0142 73.869 32.9872 75.256C39.9601 76.643 47.1878 75.9311 53.7562 73.2104C60.3246 70.4897 65.9387 65.8823 69.8886 59.9709C73.8384 54.0595 75.9467 47.1096 75.9467 40C75.9456 30.4667 72.158 21.3241 65.417 14.5831C58.6759 7.84198 49.5333 4.05441 40 4.05335ZM0 40C0 32.0888 2.34596 24.3552 6.74122 17.7772C11.1365 11.1992 17.3836 6.07234 24.6927 3.04484C32.0017 0.0173314 40.0444 -0.774802 47.8036 0.768607C55.5629 2.31202 62.6902 6.12165 68.2843 11.7157C73.8784 17.3098 77.688 24.4372 79.2314 32.1964C80.7748 39.9556 79.9827 47.9983 76.9552 55.3074C73.9277 62.6164 68.8008 68.8635 62.2228 73.2588C55.6448 77.6541 47.9113 80 40 80C29.3917 79.999 19.2181 75.7843 11.7169 68.2831C4.21567 60.7819 0.00106067 50.6084 0 40Z"
            fill="#005E83"
          />
          <path
            d="M72.9153 40.0003C72.9153 46.5103 70.9849 52.8742 67.3681 58.2871C63.7513 63.7 58.6106 67.9188 52.5962 70.4101C46.5817 72.9014 39.9635 73.5532 33.5785 72.2832C27.1936 71.0131 21.3286 67.8782 16.7253 63.2749C12.1221 58.6717 8.98717 52.8067 7.71713 46.4218C6.44708 40.0368 7.09892 33.4186 9.5902 27.4041C12.0815 21.3897 16.3003 16.249 21.7132 12.6322C27.1261 9.01541 33.49 7.08496 40 7.08496C48.7296 7.08531 57.1015 10.5533 63.2743 16.726C69.447 22.8988 72.915 31.2707 72.9153 40.0003Z"
            fill="#E31837"
          />
          <path
            d="M50.4627 45.5548C50.4627 49.8928 47.014 53.1462 42.018 53.7235V57.7148H38.258V53.6529C32.9513 52.9149 29.6627 49.3948 29.5467 44.6662L33.92 44.7462C34.1513 47.9195 36.6667 50.0528 40.4713 50.0528C43.6893 50.0528 46.0093 48.6215 46.0093 46.0262C46.0093 43.1642 43.1827 42.5415 38.8807 41.7682C34.392 40.9948 30.1253 39.5635 30.1253 34.1768C30.1253 29.9188 33.3787 26.8168 38.2587 26.2748V22.2842H42.0187V26.3908C46.7033 27.1642 49.8053 30.3822 50.0007 34.5955H45.6267C45.3153 31.8488 43.0313 29.9468 39.778 29.9468C36.72 29.9468 34.5867 31.3068 34.5867 33.8582C34.5867 36.6495 37.378 37.2268 41.6353 37.9648C46.1333 38.7815 50.5067 40.2128 50.4713 45.5548"
            fill="white"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.9867 12.151C38.9867 11.8823 39.0934 11.6245 39.2835 11.4345C39.4735 11.2445 39.7312 11.1377 40 11.1377C47.6544 11.1388 54.995 14.1799 60.4076 19.5923C65.8201 25.0047 68.8614 32.3453 68.8627 39.9997C68.8627 40.2684 68.7559 40.5262 68.5659 40.7162C68.3758 40.9063 68.1181 41.013 67.8493 41.013C67.5806 41.013 67.3228 40.9063 67.1328 40.7162C66.9428 40.5262 66.836 40.2684 66.836 39.9997C66.8349 32.8827 64.0072 26.0576 58.9747 21.0252C53.9422 15.9928 47.117 13.1652 40 13.1644C39.7312 13.1644 39.4735 13.0576 39.2835 12.8676C39.0934 12.6775 38.9867 12.4198 38.9867 12.151Z"
              fill="#005E83"
            />
          </g>
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.1513 38.9863C12.4201 38.9863 12.6778 39.0931 12.8679 39.2831C13.0579 39.4732 13.1647 39.7309 13.1647 39.9997C13.1656 47.1166 15.9931 53.9417 21.0255 58.9741C26.0579 64.0065 32.8831 66.8341 40 66.835C40.2688 66.835 40.5265 66.9418 40.7165 67.1318C40.9066 67.3218 41.0133 67.5796 41.0133 67.8483C41.0133 68.1171 40.9066 68.3748 40.7165 68.5649C40.5265 68.7549 40.2688 68.8617 40 68.8617C32.3457 68.8604 25.0053 65.8192 19.5929 60.4068C14.1804 54.9944 11.1392 47.654 11.138 39.9997C11.138 39.7309 11.2448 39.4732 11.4348 39.2831C11.6248 39.0931 11.8826 38.9863 12.1513 38.9863Z"
              fill="#005E83"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4202_13589">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
