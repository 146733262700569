import React from "react";

export const Mrna = () => {
  return (
    <svg viewBox="0 0 48 54">
      <path
        fill="#005E83"
        d="M.033 9.052v-.466a.692.692 0 01.118-.408.655.655 0 01.332-.254L23.84.02a.594.594 0 01.405 0l23.298 7.89a.655.655 0 01.336.26.692.692 0 01.115.416v.466c.26 9.81-1.508 33.805-23.697 44.938a.634.634 0 01-.568 0C1.56 42.83-.228 18.855.033 9.052zm23.996-7.64L1.338 9.086h-.013C1.07 18.584 2.787 41.79 23.997 52.632 45.214 41.79 46.956 18.584 46.688 9.086L24.03 1.412zm3.168 43.341a.642.642 0 00.461.198.644.644 0 00.432-.214.688.688 0 00.175-.462c0-4.88-2.37-7.313-4.599-9.376h2.308a.642.642 0 00.462-.198.688.688 0 000-.955.641.641 0 00-.462-.198h-3.76c-1.578-1.528-2.82-3.108-3.014-5.776h6.67c.173 0 .339-.071.461-.198a.688.688 0 000-.955.642.642 0 00-.461-.198h-6.678c.16-2.603 1.352-4.27 2.824-5.78.02.003.041.004.062.004h3.916a.641.641 0 00.461-.198.688.688 0 000-.955.642.642 0 00-.46-.198h-2.586c1.676-1.554 3.5-3.303 4.358-6.052a.692.692 0 00.125-.436c.237-.897.373-1.896.373-3.024 0-.18-.069-.35-.191-.478a.642.642 0 00-.462-.197.642.642 0 00-.461.197.688.688 0 00-.191.478c0 .881-.092 1.67-.257 2.385h-5.512a.642.642 0 00-.462.198.688.688 0 00-.19.477c0 .18.068.351.19.478a.642.642 0 00.462.198h5.085c-.832 2.034-2.31 3.424-3.806 4.83-2.265 2.128-4.601 4.337-4.601 8.68 0 4.345 2.33 6.506 4.568 8.533 1.63 1.473 3.179 2.892 3.987 5.114h-5.233a.641.641 0 00-.462.198.687.687 0 00-.19.477c0 .18.068.351.19.478a.642.642 0 00.462.198h5.608c.133.677.206 1.421.206 2.25 0 .179.07.35.192.477z"
      />
    </svg>
  );
};
