import { Theme, UnbrandedEducationTheme } from "@modernatx/ui-kit-react";

const accent01 = "#1E2846";
const accent03 = "#DF1730";

const buttonStylesLight: Partial<Theme["colorsLight"]> = {
  buttonBackgroundColorPrimary: "#E31837",
  buttonBorderColorPrimary: "#E31837",
  buttonColorSecondary: "#000000",
  buttonBorderColorSecondary: "#000000",
  buttonHoverBorderColorSecondary: "#1E2846",
  buttonActiveBorderColorSecondary: "#1E2846"
};

const buttonStylesDark: Partial<Theme["colorsDark"]> = {
  buttonColorPrimary: "#FFFFFF",
  buttonBackgroundColorPrimary: accent03,
  buttonBorderColorPrimary: accent03
};

const iconButtonStyles: Partial<Theme["colorsLight"]> = {
  iconButtonBackgroundColor: "#ECECEC",
  iconButtonColorPrimary: "#1E2846",
  iconButtonHoverBackgroundColor: "#DCDEE3",
  iconButtonActiveBackgroundColor: "#DCDEE3",
  buttonBackgroundColorDisabled: "#ECECEC"
};

export const USCovidTheme: Theme = {
  ...UnbrandedEducationTheme,
  colorsLight: {
    ...UnbrandedEducationTheme.colorsLight,
    ...buttonStylesLight,
    ...iconButtonStyles,
    accent01: accent01,
    accent02: accent01,
    backgroundNeutral04: "#F9F9FA",
    cta01: "#1E2846",
    link01: "#1E2846",
    cta02TextHover: "#1E2846"
  },
  colorsDark: {
    ...UnbrandedEducationTheme.colorsDark,
    ...buttonStylesDark,
    accent01: accent03,
    accent02: accent01,
    background01: "#1E2846",
    backgroundNeutral05: "#000"
  }
};
