import React from "react";

export const Calendar = () => {
  return (
    <svg viewBox="0 0 54 53">
      <path
        fill="#005E83"
        d="M.72 53H53.28c.433 0 .721-.292.721-.729V5.759c0-.437-.288-.729-.721-.729H46.79V.73c0-.437-.288-.729-.72-.729h-3.822c-.432 0-.72.292-.72.729V5.03H12.616V.73c0-.437-.289-.729-.721-.729H8.075c-.433 0-.721.292-.721.729V5.03H.72c-.433 0-.721.292-.721.73V52.27c0 .437.36.729.72.729zm21.774-18.809v-7.217h9.084v7.217h-9.084zm9.084 1.458v7.218h-9.084v-7.218h9.084zm-19.61-8.748h9.084v7.217h-9.084v-7.217zm-1.442 7.29H1.442v-7.217h9.084v7.217zm22.494-7.29h9.084v7.217H33.02v-7.217zm10.526 0h9.084v7.217h-9.084v-7.217zm9.012-1.458h-9.012V18.37h9.084v7.072h-.072zm-10.454 0H33.02V18.37h9.084v7.072zm-10.526 0h-9.084V18.37h9.084v7.072zm-10.526 0h-9.084V18.37h9.084v7.072zm-10.526 0H1.442V18.37h9.084v7.072zM1.442 35.649h9.084v7.218H1.442v-7.218zm10.526 0h9.084v7.218h-9.084v-7.218zm9.084 8.676v7.217h-9.084v-7.217h9.084zm1.442 0h9.084v7.217h-9.084v-7.217zm10.526 0h9.084v7.217H33.02v-7.217zm0-1.458v-7.218h9.084v7.218H33.02zm10.526-7.218h9.084v7.218h-9.084v-7.218zm9.012-18.736H1.442v-1.75h51.116v1.75zM1.442 44.325h9.084v7.217H1.442v-7.217zm42.104 7.217v-7.217h9.084v7.217h-9.084zM42.97 1.458h2.38v6.999h-2.38V1.458zm-34.173 0h2.379v6.999h-2.38V1.458zm-1.442 5.03v2.698c0 .437.288.729.72.729h3.822c.432 0 .72-.292.72-.73V6.489H41.6v2.698c0 .437.289.729.721.729h3.822c.432 0 .72-.292.72-.73V6.489h5.768v7.29H1.442v-7.29h5.912z"
      />
    </svg>
  );
};
