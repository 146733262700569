import React from "react";

export const Snowflake = () => {
  return (
    <svg viewBox="0 0 54 54">
      <path
        fill="#005E83"
        d="M53.16 33.257a1.156 1.156 0 01-.846 1.397l-8.267 2.023 2.17 8.128a1.155 1.155 0 01-1.106 1.457 1.156 1.156 0 01-1.116-.867l-2.362-8.787-13.875-8.01v16.017l6.6 6.599a1.159 1.159 0 01-.376 1.887 1.158 1.158 0 01-1.26-.251l-6.12-6.12-6.12 6.12a1.157 1.157 0 01-1.636-1.636l6.6-6.6V28.599l-13.875 8.01-2.353 8.776a1.156 1.156 0 01-1.116.867 1.156 1.156 0 01-1.107-1.457l2.17-8.108-8.267-2.024a1.16 1.16 0 01.555-2.252l8.961 2.2 13.875-8.016-13.875-8.015-8.96 2.2a1.178 1.178 0 01-.29.034 1.156 1.156 0 01-.289-2.278l8.268-2.023-2.157-8.129a1.156 1.156 0 012.232-.578l2.353 8.776 13.875 8.01V8.574l-6.6-6.599A1.157 1.157 0 1120.482.339l6.12 6.12 6.12-6.12a1.156 1.156 0 111.635 1.636l-6.599 6.6V24.59l13.875-8.01 2.353-8.776a1.156 1.156 0 012.232.579l-2.171 8.108 8.267 2.023a1.157 1.157 0 01-.275 2.298c-.097 0-.194-.011-.289-.034l-8.96-2.2-13.876 8.015L42.79 34.61l8.961-2.2a1.156 1.156 0 011.41.847z"
      />
    </svg>
  );
};
