import React from "react";

export const Thermometer = () => {
  return (
    <svg viewBox="0 0 53 132">
      <path
        d="M26.776 130.322c-13.302 0-24.131-10.854-24.131-24.186 0-7.457 3.497-14.413 9.151-18.933V17.647c0-8.277 6.722-15.014 14.98-15.014s14.98 6.737 14.98 15.014v69.565c5.654 4.521 9.15 11.477 9.15 18.934 0 13.332-10.828 24.186-24.13 24.186"
        fill="#fff"
      />
      <path
        d="M26.776 4.305c-7.338 0-13.313 5.99-13.313 13.343v69.555c0 .509-.231.99-.628 1.306-5.27 4.214-8.524 10.695-8.524 17.628 0 12.41 10.083 22.515 22.465 22.515q.094 0 .184.01c12.297-.099 22.28-10.167 22.28-22.514 0-6.933-3.254-13.414-8.524-17.628a1.67 1.67 0 0 1-.628-1.306V17.648c0-7.354-5.975-13.343-13.312-13.343m-.198 127.688c-14.132-.108-25.6-11.668-25.6-25.856 0-7.702 3.483-14.89 9.151-19.722V17.648C10.129 8.45 17.597.964 26.776.964s16.646 7.485 16.646 16.684v68.778c5.669 4.832 9.151 12.02 9.151 19.722 0 14.255-11.575 25.857-25.797 25.857q-.101 0-.198-.012"
        fill="#E31837"
      />
      <path
        d="M26.776 120.321c-7.8 0-14.141-6.366-14.141-14.173 0-5.918 3.704-11.192 9.151-13.255V72.736c0-2.763 2.244-5.001 4.99-5.001s4.99 2.238 4.99 5v20.158c5.446 2.063 9.15 7.337 9.15 13.255 0 7.818-6.34 14.173-14.14 14.173"
        fill="#005E83"
      />
      <path
        d="M5.018 39.465c0-.922.746-1.67 1.667-1.67h9.99c.92 0 1.667.748 1.667 1.67 0 .923-.746 1.671-1.667 1.671h-9.99a1.67 1.67 0 0 1-1.667-1.67"
        fill="#005E83"
      />
      <path
        d="M5.018 51.466c0-.923.746-1.67 1.667-1.67h9.99c.92 0 1.667.747 1.667 1.67s-.746 1.67-1.667 1.67h-9.99a1.67 1.67 0 0 1-1.667-1.67"
        fill="#005E83"
      />
      <path
        d="M5.018 63.466c0-.923.746-1.67 1.667-1.67h9.99c.92 0 1.667.747 1.667 1.67s-.746 1.67-1.667 1.67h-9.99c-.92 0-1.667-.747-1.667-1.67"
        fill="#005E83"
      />
      <path
        d="M5.018 75.477c0-.922.746-1.67 1.667-1.67h9.99c.92 0 1.667.748 1.667 1.67 0 .923-.746 1.67-1.667 1.67h-9.99c-.92 0-1.667-.747-1.667-1.67"
        fill="#005E83"
      />
    </svg>
  );
};
