import { ModernaTheme, Theme } from "@modernatx/ui-kit-react";

export const ProCovidTheme: Theme = {
  ...ModernaTheme,
  colorsDark: {
    ...ModernaTheme.colorsDark,
    background01: "#1E2846"
  },
  colorsLight: {
    ...ModernaTheme.colorsLight,
    accent01: "#1E2846",
    accent02: "#56B4E9",
    accent03: "#1E2846",
    background01: "#FFFFFF",
    background02: "#F2FAFD",
    backgroundNeutral01: "#F9F9FA",
    backgroundNeutral02: "#EAEBEF",
    buttonBackgroundColorPrimary: "#1E2846",
    buttonBorderColorPrimary: "#1E2846",
    tableHeaderBackgroundColor: "#1E2846",
    text01: "#1E2846",
    text02: "#383D47",
    text03: "#85888E",
    text04: "#FFFFFF"
  },
  radii: {
    ...ModernaTheme.radii,
    buttonBorderRadius: 50
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 40, 48, 60, 80, 96],
  lineHeights: [16 / 12, 20 / 14, 1.5, 28 / 18, 1.4, 32 / 24, 36 / 30, 40 / 36, 48 / 40, 1, 1, 1, 1]
};
